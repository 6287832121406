<template>
  <body class="registration_wraapper">
    <!-- <div class="frames">
            <img src="./../assets/front/image/mobile_frame2.png" class="bottom_frame">
            <img src="./../assets/front/image/mobile_frame4.png">
        </div>-->

    <div class="frame">
      <div class="site-wrapper overflow-hidden">
        <section class="user_login h-100">
          <div class="row no-gutters align-items-center h-100">
            <div class="col-xl-4 offset-xl-7 col-lg-6 offset-lg-6 col-md-12">
              <h1>Verify your Otp</h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              <div v-if="error" class="alert alert-danger">{{ error }}</div>
              <form id="signin-form" v-on:submit.prevent="submit">
                <div class="form-group">
				   <label>Otp</label>
				  <input
                    id="otp"
                    type="number"
                    name="otp"
                    v-model.trim="$v.otp.$model"
                    :class="{ 'is-invalid': validationStatus($v.otp) }"
                    class="form-control"
                  />
                  <div v-if="!$v.otp.required" class="invalid-feedback">
                    The otp field is required.
                  </div>
                 <!--  <div v-if="!$v.otp.number" class="invalid-feedback">
                    The otp is not valid.
                  </div> -->

                </div>
				  <div class="form-group">
				   <label>Password</label>
				  <input
                    id="Password"
                    type="Password"
                    name="Password"
                    v-model.trim="$v.Password.$model"
                    :class="{ 'is-invalid': validationStatus($v.Password) }"
                    class="form-control"
                  />
                  <div v-if="!$v.Password.required" class="invalid-feedback">
                    The Password field is required.
                  </div>
                  
                </div>
				<div class="form-group">
				   <label>Confirm Password</label>
				  <input
                    id="cPassword"
                    type="password"
                    name="cPassword"
                    v-model.trim="$v.cPassword.$model"
                    :class="{ 'is-invalid': validationStatus($v.cPassword) }"
                    class="form-control"
                  />
                  <div v-if="!$v.cPassword.required" class="invalid-feedback">
                    The Confirm Password field is required.
                  </div>
                  
                </div>


                <p class="text-right"></p>
                <p class="text-center mt-4">
                  <button class="btn btn-primary">Submit</button>
                </p>
                <p class="text-center">
                  
                  <router-link to="/signin">Sign in </router-link>
                  <br /><br />
                  <router-link to="/">Back to Home</router-link>
                </p>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  </body>
</template>

<script>
export default {};
</script>
<script>
// import {
//   required,
// } from "vuelidate/lib/validators";
import Vue from "vue";
import axios from "axios";
// import VueAxios from "vue-axios";
import firebase from "firebase";
import { db } from "@/main";
// import emailjs from 'emailjs-com';
// import{ init } from 'emailjs-com';
import Router from 'vue-router';

export default {
  name: "Login",
  data: function() {
    return {
      otp: "",
      Password: "",
      uid: "",
      cPassword: "",
      Ustatus: "",
      error: ""
    };
  },
  validations: {
    otp: { required },
    Password: { required },
    cPassword: { required },
  },
  methods: {
    validationStatus: function(validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    submit: function() {
	  var match = false;
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
         var otpfromuser = this.otp;
		var max_chars = 6;
		
		function show() {
		  this.$toasted.error('Error, Please insert 6  digits number', {
			//icon : 'check',
			icon : 'error_outline'
		 })
		}
		if((this.otp).length != max_chars){
            this.$toasted.error('Error, Please insert 6  digits number', {
                        //icon : 'check',
                        icon : 'error_outline'
                     })
	    }else if(this.Password != this.cPassword ){
		   this.$toasted.error('Error, Password Not maching', {
                        //icon : 'check',
                        icon : 'error_outline'
                     })
		}else{
		 var Password = this.Password;
		 var forgot_otps;
		 var otp_update;
		 var today;
		 var dd;
		 var ddd;
		 var mm;
		 var mmm;
		 var yyyy;
		 var yyyyy;
		 var user_id;
		 
		 var  datefromdata
	        this.uid= localStorage.getItem("uid");
		     db.collection("users").where("user_id", "==",this.uid)
						.get()
						.then(function(querySnapshot) {
							querySnapshot.forEach(function(doc) {
								forgot_otps = doc.data().forgot_otp;
								  otp_update = doc.data().otp_update;
								  user_id = doc.data().user_id;
							     
							   
							    today  = new Date();
							    dd = String(today.getDate()).padStart(2, '0');
								mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
								yyyy = today.getFullYear();
							 
							
							     datefromdata = otp_update.split('/');
						
								 ddd = datefromdata[0];
								 mmm = datefromdata[1];
								 yyyyy = datefromdata[2];
								 
								 if(otpfromuser ==  forgot_otps ){
								 	if(yyyyy != yyyy && mmm != mm && ddd !=dd ){
									 alert("Otp Expired");
									}else{
									      db.collection("users")
										.doc(doc.id)
										.update({
										  password:Password,
										   
										})
										.then(() => {
										      
											alert("Password Updated!");
											localStorage.clear();
											window.location.href="https://https://intro-ae-new.netlify.app/"
										
										})
										.catch((error) => {
										  // The document probably doesn't exist.
										  console.error("Error updating document: ", error);
										});
									}						   
								 }else{
								  alert("Otp Expired");
								 }
								 
								 
							   /*
							   var time = otp_date.split(',')[1];
							           db.collection("users")
										.doc(doc.data().email)
										.update({
										  password:Password,
										})
										.then(() => {
										  console.log("Document successfully updated!");
										 

										})
										.catch((error) => {
										
										  console.error("Error updating document: ", error);
										});
							   */
							   
                               
							});
						})
						.catch(function(error) {
							console.log("Error getting documents: ", error);
						});
						if(match == true){
						 this.$router.push('Profile')
						 }
     
	 }
    },
  },
};
</script>


<style scoped>
.user_login {
  height: 86vh;
}
.user_login h1 {
  font-size: 30px;
  margin-bottom: 20px;
}
.user_login form {
  margin-top: 50px;
}
.user_login .form-control {
  background: #f2f4f5 !important;
  height: 40px;
  border-color: #f2f4f5;
}
.user_login .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #dadcdc;
  outline: 0;
}
.user_login label {
  display: block;
}
.user_login .row.h-100 {
  position: relative;
}
.user_login .row.h-100:before {
  background: url(../../assets/front/image/sign_in.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  content: "";
  width: 50%;
  height: 100%;
}
.frames {
  position: absolute;
  height: 100vh;
}
.frames .bottom_frame {
  position: absolute;
  bottom: 0;
}
.user_login .row.h-100 .col-xl-4 {
  background: #fff;
  padding: 25px;
  border-radius: 5px;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.user_login .row.h-100 .col-xl-4::-webkit-scrollbar,
.frame:before,
.frame:after {
  display: none;
}
.site-wrapper {
  height: 100vh;
  border: none;
  border-radius: 0;
}
body {
  background: #fff;
}
.user_login {
  margin-top: 0;
}



.registration_wraapper::after, .registration_wraapper::before {
    position: absolute;
    content: "";
    z-index: 9;
    transform: translateX(-50%);
}

.registration_wraapper::before {
    width: 120px;
    height: 10px;
    background: #fff;
    top: 32px;
    left: 50%;
    border-radius: 20px;
    opacity: .5;
}

.registration_wraapper::after {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    top: 29px;
    left: calc(50% - 90px);
    background: #898b8d;
}
.site-wrapper {
    margin: 0 auto;
    position: relative;
    border: 25px solid #14171b;
        border-top-width: 25px;
        border-bottom-width: 25px;
    border-top-width: 25px;
    border-bottom-width: 25px;
    border-radius: 119px;
    border-top-width: 72px;
    z-index: 8;
    border-bottom-width: 72px;
}




@media only screen and (max-width: 992px) {
  .frames,
  .user_login .row.h-100:before {
    display: none;
  }
  .user_login {
    height: 100vh;
    margin-top: 0;
  }
  .user_login > .row {
    background: none;
    padding: 0px 15px;
  }
}
@media only screen and (max-width: 600px) {
  .user_login .row.h-100 .col-xl-4 {
    justify-content: flex-start;
  }
    .site-wrapper.overflow-hidden {
    border: none;
}
.registration_wraapper[data-v-72666184]::after, .registration_wraapper[data-v-72666184]::before {
   display:none;
}
}
</style>
